<template>
  <div>
    <div class="main-box-white block-box">
      <section class="latest-status" :class="{ active: isSearch == '' }" @click="getData('')">
        <div>
          <span class="iconfont icon-quanbu status-wait"></span>
        </div>
        <div>
          <span class="status-num">{{ acceptSuccessNumber + handleFailureNumber }}</span>
          <p>全部</p>
        </div>
      </section>
      <section class="latest-status" :class="{ active: isSearch == 'completed' }" @click="getData('completed')">
        <div>
          <span class="iconfont icon-daichuli status-success"></span>
        </div>
        <div>
          <span class="status-num">{{ acceptSuccessNumber }}</span>
          <p>验收完成</p>
        </div>
      </section>
      <section class="latest-status" :class="{ active: isSearch == 'handledFailure' }" @click="getData('handledFailure')">
        <div>
          <span class="iconfont icon-daiyanshou status-fail"></span>
        </div>
        <div>
          <span class="status-num">{{ handleFailureNumber }}</span>
          <p>暂不受理</p>
        </div>
      </section>
    </div>
    <div class="main-box-white">
      <list-container
        :grid="false"
        ref="listContainerDom"
        placeholder="请输入关键字"
        :searchText.sync="searchText"
        :pagination="pagination"
        :uploadFile="uploadFile"
        :isAdd="false"
        @add="add"
        @exportFile="exportFile"
        @fetch="loadData"
        @titleSearch="titleSearch($event)"
        :data="exportData"
        :fields="fields"
        excelName="已完成的事项列表.xls"
      >
        <template v-slot:filter>
          <div class="select-box">
            <el-button style="margin-left: 20px" class="iconfont icon-dcshaixuan1" @click="drawer = true">高级搜索</el-button>
          </div>
        </template>
        <template v-slot:table>
          <base-table :data="data" :columns="columns" :isCheckTwo="true" :operations="operations" :multi="false" @set="set($event)" />
        </template>
      </list-container>
      <el-drawer title="高级搜索" :visible.sync="drawer" direction="rtl" custom-class="search-drawer">
        <searchForm @search="search" :orderStatus="statusList" userTxt="发起人" :isComleted="true" tipTxt="发起人所在部门" />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import listContainer from '@/components/list-container/list-container';
import baseTable from '@/components/table/base-table/base-table';
import searchForm from '@/components/advanced-search/search-form';
import { formatDate } from '@/common/js/utils';
import { TABLE } from '/app.config';
import { table } from './mixin';
export default {
  name: 'completed',
  mixins: [table],
  data() {
    return {
      acceptSuccessNumber: 0,
      handleFailureNumber: 0,
      data: [],
      exportData: [],
      drawer: false,
      searchText: '',
      multipleSelection: [],
      pagination: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      },
      searchParams: {},
      orderType: '',
      problemType: '',
      isSearch: '',
      status: '',
      statusList: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'completed',
          label: '验收完成'
        },
        {
          value: 'handledFailure',
          label: '暂不受理'
        }
      ],
      fields: {
        创建日期: {
          field: 'createTime',
          callback: value => {
            return formatDate(value);
          }
        },
        一级分类: 'orderClassficationName',
        二级分类: 'matterClassficationName',
        问题性质: 'matterPropertyName',
        标题: 'title',
        事项描述: {
          field: 'des',
          callback: value => {
            return `&nbsp;${value}`;
          }
        },
        建议及期望: {
          field: 'advice',
          callback: value => {
            return `&nbsp;${value}`;
          }
        },
        处理描述: {
          field: 'receiptDesc',
          callback: value => {
            return `&nbsp;${value}`;
          }
        },
        发起人: 'createName',

        事项状态: 'statusName'
      },
      uploadFile: ['export']
    };
  },
  activated() {
    this.loadData();
    this.getCompleteNum();
  },
  methods: {
    async loadData({ currentPage, currentPageSize } = {}) {
      this.pagination.currentPage = currentPage || this.pagination.currentPage;
      this.pagination.pageSize = currentPageSize || this.pagination.pageSize;
      const params = {
        statuss: this.isSearch,
        title: this.searchText,
        page: this.pagination.currentPage,
        limit: this.pagination.pageSize
      };
      const newParams = Object.assign(params, this.searchParams);
      this.loadDataAll(newParams);
      // this.loadDataAll(params);
    },
    async getCompleteNum() {
      const { data } = await this.$apis.workOrder.getCompleteNum();
      this.handleFailureNumber = data.handleFailureNumber;
      this.acceptSuccessNumber = data.acceptSuccessNumber;
    },
    async loadDataAll(params) {
      const { data, count } = await this.$apis.workOrder.completeList(params);
      this.data = data;
      this.pagination.total = count;
      this.getAll();
    },
    //导出数据
    async getAll() {
      const params = {
        statuss: this.isSearch,
        title: this.searchText
      };
      const newParams = Object.assign(params, this.searchParams);
      const { data } = await this.$apis.workOrder.getComAllList(newParams);
      this.exportData = data;
    },
    async changOrder(params) {
      const data = await this.$apis.workOrder.edit(params);
      if (data.resp_code !== 0) {
        this.$message({
          type: 'error',
          message: data.resp_msg
        });
      }
    },
    //状态筛选
    getData(status) {
      if (!status) {
        this.searchParams = {};
      }
      this.isSearch = status;
      let params = {
        page: 1,
        limit: this.pagination.pageSize,
        statuss: status
      };
      //  const newParams = Object.assign(params, this.searchParams);
      this.loadDataAll(params);
    },
    // 新增
    add() {
      this.$router.push({ name: 'system_personal_add' });
    },
    // 删除
    del({ id }) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fail } = await this.$apis.sysUser.del({ ids: id });
        if (!fail) {
          this.loadData();
        }
      });
    },
    // 编辑
    edit({ id }) {
      this.$router.push({
        name: 'system_personal_edit',
        params: {
          id
        }
      });
    },
    // 详情
    view({ id }) {
      this.$router.push({ name: 'completed_detail', params: { id } });
    },
    //高级筛选
    search(params) {
      this.drawer = false;
      this.searchParams = params;
      this.$refs.listContainerDom.clear();
      let newParams = Object.assign({}, params);
      newParams.page = 1;
      newParams.limit = this.pagination.pageSize;
      this.loadDataAll(newParams);
    },
    //导出
    exportFile() {},
    //设置是否可见
    set({ orderId, visible }) {
      console.log(orderId, visible);
      const h = this.$createElement;
      if (visible === 0) {
        this.$msgbox({
          title: '提示消息',
          message: h('p', null, [h('span', null, '确定关闭可见？ '), h('p', null, '关闭后，该案例将不能在企业案例库中查询。')]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              setTimeout(() => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }, 3000);
            } else {
              done();
            }
          }
        }).then(() => {
          this.changOrder({
            orderId,
            visible
          });
        });
      } else {
        this.$msgbox({
          title: '提示消息',
          message: h('p', null, [h('span', null, '确定公开可见？ '), h('p', null, '设置公开后，企业人员可在案例库中查看该案例。')]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              setTimeout(() => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }, 3000);
            } else {
              done();
            }
          }
        }).then(() => {
          this.changOrder({
            orderId,
            visible
          });
        });
      }
    },
    titleSearch(searchText) {
      this.searchText = searchText;
      this.searchParams = {};
      this.loadData();
    }
  },
  watch: {
    // searchText() {
    //   this.searchParams = {};
    //   this.loadData();
    // }
  },
  components: {
    listContainer,
    baseTable,
    searchForm
  }
};
</script>

<style lang="scss" scoped>
.block-box {
  display: flex;
  margin-bottom: 10px;
}
.latest-status {
  width: 184px;
  height: 64px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-right: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(18, 18, 18, 0.16);
  }
  &.active {
    box-shadow: 0px 2px 10px 0px rgba(18, 18, 18, 0.16);
  }
  .status-num {
    font-size: 20px;
    display: block;
    text-align: right;
    font-family: YouSheBiaoTiHei;
  }
  p {
    color: #5a5a5a;
    font-size: 14px;
    line-height: 1;
  }
  .iconfont {
    font-size: 30px;
  }
}
</style>
