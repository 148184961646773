import { TABLE } from '/app.config';

const { view, set } = TABLE.icons;

const table = {
  data() {
    return {
      columns: [
        {
          width: 200,
          prop: 'createName',
          label: '发起人',
          noUser: false,
          avatar: 'reporterAvatar',
          other: 'orderCreateDept'
        },
        {
          width: 450,
          prop: 'orderInfo',
          label: '事项信息',
          noUser: true,
          hasInfo: true,
          other: ['orderId', 'reportTime', 'title']
        },
        {
          width: 150,
          prop: 'orderClassficationName',
          label: '事项类型',
          noUser: true
        },
        {
          width: 150,
          prop: 'matterClassficationName',
          label: '问题分类',
          noUser: true
        },
        {
          width: 150,
          prop: 'matterPropertyName',
          label: '问题性质',
          noUser: true
        },
        {
          width: 150,
          prop: 'totalHours',
          label: '办结时长',
          noUser: true
        },
        {
          width: 150,
          prop: 'completeTimeStr',
          label: '完成时间',
          noUser: true,
          time: true
        },
        {
          width: 150,
          prop: 'statusName',
          label: '处理结果',
          noUser: true,
          dot: true
        },
        {
          width: 100,
          switch: true,
          prop: 'visible',
          status: 'statusName',
          label: '是否可见',
          noUser: true
        }
      ],
      operations: {
        handles: [
          {
            icon: view,
            name: '查看',
            handle: this.view
          },
          {
            icon: set,
            name: '设置是否可见',
            handle: this.set
          }
        ]
      }
    };
  }
};
export { table };
